<template>
  <div class="Activity">

    <div class="form">
      <el-form :inline="true" label-width="100px" :model="form" @submit.native.prevent >
        <el-row>
          <el-col :span="7">
            <el-form-item label="轮播图名称" prop="title">
              <el-input v-model="form.title" clearable placeholder="请输入轮播图名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="状态" prop="status">
              <el-select v-model="form.status" clearable placeholder="请选择状态">
                <el-option label="展示" value="1"></el-option>
                <el-option label="隐藏" value="0"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" native-type="submit" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>


    <div class="list" style="min-height: calc(100vh - 214px)">
      <div class="operation">
        <el-button @click="addClick" size="small" type="primary">添加轮播图</el-button>
      </div>

      <el-table
          :data="table"
          v-loading="tableLoad"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="width: 100%">
        <el-table-column
            prop="productName"
            align="center"
            label="轮播图名称">
          <template slot-scope="scope">
            <span class="blue" @click="deta(scope.row)" >{{ scope.row.title }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="bannerUrl"
            align="center"
            label="轮播图">
          <template slot-scope="scope">
            <el-image
                style="width: 50%; height: 50px"
                :src="scope.row.bannerUrl"
                fit="contain"
                :preview-src-list="[scope.row.bannerUrl]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
            prop="jumpUrl"
            align="center"
            label="跳转链接">
          <template slot-scope="scope">
            <span v-if="scope.row.isExternalUrl" >{{ scope.row.jumpUrl }}</span>
            <span v-else>{{ "http://health.endosmart.cn" + scope.row.jumpUrl }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="externalUrl"
            align="center"
            label="是否外部链接">
          <template slot-scope="scope">
            <span v-if="scope.row.isExternalUrl" >是</span>
            <span v-else >否</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="sequence"
            align="center"
            label="排列次序">
        </el-table-column>
        <el-table-column
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <div class="operation" style="justify-content: center" >
              <el-switch
                  v-model="scope.row.status"
                  @change="switchChange($event,scope.row)"
                  active-value="1"
                  inactive-value="0"
                  active-text="展示"
                  inactive-text="隐藏">
              </el-switch>
              <el-button type="text" size="small" style="color: #f56c6c; margin-left: 20px; " @click="del(scope.row)" >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  name: "Activity",
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 10,
        status: null,
        title: null,
      },
      total: 0,
      tableLoad: true,
      table: [],
    }
  },
  created() {
    this.getList(this.form);
  },
  methods: {
    getList(y) {

      this.$get("activityManager/banner",y)
        .then(res => {
          this.tableLoad= false;

          if(res) {
            console.log(res)

            this.table= res.data.list;
            this.total= res.data.total;

          }

        })

    },
    // 状态
    switchChange(e,row) {
      console.log(e);
      console.log(row);

      let obj= {};
      obj["id"]= row.id;
      obj["isShow"]= e;

      this.$post("activityManager/banner/show",obj)
        .then(res => {
          if(res) {
            this.form.pageNum= 1;
            this.getList(this.form)
          }
        })

    },
    // 删除
    del(e) {

      this.$confirm('此操作将删除此活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$del("activityManager/banner",e.id,true)
            .then(res => {
              if(res) {
                if(res) {
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.form.pageNum= 1;
                  this.getList(this.form)
                }
              }
            })
      }).catch(() => {

      });

    },
    handleCurrentChange(e) {
      console.log(`第${e}页`);
      this.form.pageNum= e;
      this.getList(this.form);
    },
    handleSizeChange(e) {
      console.log(`每页${e}条`);
      this.form.pageNum= 1;
      this.form.pageSize= e;
      this.getList(this.form);
    },
    onSubmit(formName) {
      formName.pageNum= 1;

      this.getList(formName);

    },
    deta(e) {

      this.$router.push({
        path: "/ActivityDeta",
        query: {
          id: e.id
        }
      })

    },
    addClick() {

      this.$router.push({
        path: "/ActivityDeta"
      })

    },
  }
}
</script>

<style lang="less" scoped>
  .Activity{
    height: 100%;
  }
</style>
